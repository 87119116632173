@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;800;700;600;500;400900&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.arrowLeft {
position: absolute;
left:15px;
top:40%;
font-size: 35px;

}
.KeynoteImage{
/* margin-top: 5%*/
}
.arrowRight{
  position: absolute;
  right:25px;
  top:40%;
  font-size: 35px;
}
.homeIcon{
  position: absolute;
  top:10px;
  left:10px;
  font-size:25px;
}
.areaButton{
  margin:10px !important;
  min-width: 200px !important;
  background-color: #01718f !important;
  color: white !important;
}
.fullScreenIcon{
  position: absolute;
  bottom:10px;
  right:10px;
  font-size:25px;
}
.fullScreenIconExit{
  position: absolute;
  bottom:10px;
  right:10px;
  font-size:25px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
